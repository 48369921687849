
import { Options, Vue } from "vue-property-decorator";
import api from "@/api/mynotice";
@Options({
  name: "Mynotice",
})
export default class extends Vue {
  refreshing = false;
  loading = false;
  finished = false;
  noticeList: any[] = [];
  page = {
    pageNum: 1,
    pageSize: 10,
  };
  onLoad() {
    setTimeout(async () => {
      if (this.refreshing) {
        this.noticeList = [];
        this.page.pageNum = 1;
        this.refreshing = false;
      }
      const res: any = await api.mobilemsgpage(this.page);
      if (res.code !== 0) {
        return this.$toast.fail(res.msg);
      }
      this.noticeList = [...this.noticeList, ...res.data.records];
      this.page.pageNum += 1;
      this.loading = false;
      if (this.noticeList.length >= res.data.total) {
        this.finished = true;
      }
    }, 1000);
  }
  setallreaded() {
    api.mobilemsgreadAll().then((res: any) => {
      if (res.code !== 0) {
        return this.$toast.fail(res.msg);
      }
      this.refreshing = true;
      this.onRefresh();
    });
  }
  onRefresh() {
    // 清空列表数据
    this.finished = false;
    // 重新加载数据
    // 将 loading 设置为 true，表示处于加载状态
    this.loading = true;
    this.onLoad();
  }
  fGoNoticeDetail(param: any) {
    this.$router.push({
      name: "Noticedetail",
      params: { id: param.id },
    });
  }
}
