import request from "@/utils/request";
export default {
  // 消息通知列表（分页）
  mobilemsgpage(args: any) {
    return request({
      url: "/center/mobile/msg/page",
      method: "get",
      params: args,
    });
  },
  // 消息详情
  mobilemsggetById(args: any) {
    return request({
      url: "/center/mobile/msg/getById/" + args,
      method: "get",
    });
  },
  // 设置消息已读
  mobilemsgreadById(args: any) {
    return request({
      url: "/center/mobile/msg/readById/" + args,
      method: "get",
    });
  },
  // 消息删除
  mobilemsgdeleteById(args: any) {
    return request({
      url: "/center/mobile/msg/deleteById/" + args,
      method: "get",
    });
  },
  // 未读消息数
  mobilemsgcountUnread(args: any) {
    return request({
      url: "/center/mobile/msg/countUnread",
      method: "get",
      params: args,
    });
  },
  // 全部已读
  mobilemsgreadAll() {
    return request({
      url: "/center/mobile/msg/readAll",
      method: "get",
    });
  },
};
